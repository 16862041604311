import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Contact from "../../component/contact/Contact";
import { UserContext } from "../../context/UserContext";
import { CartContext } from "../../context/CartContext";
import { Link } from "react-router-dom";
import axios from "axios";
import { ColorRing } from "react-loader-spinner";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ContactLiveChatButton from "./ContactLiveChatButton";

export default function Cart() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { token } = useContext(UserContext);
  const {
    cartData,
    removeFromCart,
    clearCart,
    calculateCartTotalPrice,
    couponCode,
    setCouponCode,
    validationResult,
    setValidationResult,
    calculateDiscountedPrice,
  } = useContext(CartContext);
  const [loading, setLoading] = useState(false);
  if (loading) {
    <>
      <div className="d-flex position-fixed z-10 justify-content-center align-items-center w-100 vh-100">
        <ColorRing
          visible={true}
          height="80"
          width="80"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
          colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
        />
      </div>
    </>;
  }

  const handlePurchase = async () => {
    try {
      setLoading(true);

      const items = cartData.map((game) => {
        if (game.type === "powerlevel") {
          return {
            type: "skill",
            // game_id: game.gameId,
            skill_id: game.skillId,
            min_level: game.levelStart,
            max_level: game.levelDesired,
            boost_method_id: game.BoostMethodId ?? 0,
            express: game.express ? true : false,
          };
        } else if (game.type === "quest") {
          return {
            type: "quest",
            // game_id: game.gameId,
            quest_id: game.quest_id,
            // quest_Difficulty: game.quest_Difficulty,
            // totalPrice:game.totalPrice
          };
        } else if (game.type === "service") {
          return {
            type: "service",
            service_id: game.selectedItem.selectedRadio?.id
              ? game.selectedItem.selectedRadio?.id
              : game.serviceId,
            checkboxes: game.selectedItem.selectedCheckboxes || [],
            quantity: game.quantity,
          };
        }

        return null; // Handle other types if needed
      });

      const requestBody = {
        items,
        coupon: couponCode, // Assuming couponCode is the variable holding the coupon code
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/place-order`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success(response.data.message);

      clearCart(); //% will be removed till Binance Payment
      navigate("/profile");
    } catch (error) {
      console.error("An error occurred during purchase:", error);
    } finally {
      setLoading(false);
    }
  };

  //@ Coupon
  const validateCoupon = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/validate-coupon-code`,
        {
          coupon: couponCode,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Assuming the API response structure is similar to the provided example
      const { message, status, discount } = response.data;

      setValidationResult({ message, status, discount });
    } catch (error) {
      // Handle error (e.g., invalid coupon code)
      console.error("Coupon validation error:", error.message);
      setValidationResult({
        message: "Invalid coupon code",
        status: "invalid",
      });
    }
  };

  useEffect(() => {
    setCouponCode(null);
    setValidationResult(null);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Cart</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <section id="Cart" className="min-vh-100 h-custom mb-5">
        <div className="container py-5">
          <div className="row d-flex justify-content-center align-items-center ">
            <div className="col-12">
              <div className=" bg-white">
                <div className=" p-0">
                  <div className="row g-0 shadow-lg">
                    <div className="col-lg-8 p-4 text-blue">
                      <div className="d-flex justify-content-between align-items-center mb-5 ">
                        <h1 className="fw-bold mb-0 ">Shopping Cart</h1>
                        <div>
                          <h5 className="mb-0 text-center ">
                            <span className="badge bg-success">
                              {cartData.length} items
                            </span>
                          </h5>
                        </div>
                      </div>
                      {cartData.map(
                        (game) =>
                          //% powelvel
                          game.type === "powerlevel" && (
                            <div key={game.id}>
                              <hr className="my-4" />
                              <div className="row mb-4 d-flex justify-content-between align-items-center ">
                                <div className="col-md-2 col-lg-2 col-xl-2">
                                  <img
                                    src={game.gameImg}
                                    className="img-fluid rounded-3"
                                    alt={game.name}
                                  />
                                </div>
                                <div className="col-md-4 col-lg-4 col-xl-4">
                                  <h5 className="">{game.type}</h5>
                                  <h6 className="">{game.gameName}</h6>
                                  <h6 className=" mb-0">
                                    Skill to Boost: {game.SkillToBoost}
                                  </h6>
                                  {game.ChooseSkill ? (
                                    <h6 className=" mb-0">
                                      Choose Skill : {game.ChooseSkill}
                                    </h6>
                                  ) : null}

                                  <h6 className=" mb-0 text-success">
                                    <span className="text-primary me-2">
                                      levels from
                                    </span>
                                    {game.levelStart} to {game.levelDesired}
                                  </h6>
                                  {game.BoostMethod ? (
                                    <h6 className="mb-0">
                                      Boost Method: {game.BoostMethod}
                                    </h6>
                                  ) : null}

                                  <h6 className=" mb-0">
                                    Express: {game.express}
                                  </h6>
                                </div>
                                <div className="col-md-2 col-lg-2 col-xl-2 offset-lg-1">
                                  <h6 className="mb-0">
                                    $ {Number(game.totalPrice).toFixed(3)}
                                  </h6>
                                </div>
                                <div className="col-md-1 col-lg-1 col-xl-1 text-end">
                                  <button
                                    className="btn btn-outline-danger"
                                    onClick={() => removeFromCart(game.id)} // Pass the id of the item to remove
                                  >
                                    <i className="fas fa-times" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          )
                      )}
                      {cartData.map(
                        (game) =>
                          //% quest
                          game.type === "quest" && (
                            <div key={game.id}>
                              <hr className="my-4" />
                              <div className="row mb-4 d-flex justify-content-between align-items-center ">
                                <div className="col-md-2 col-lg-2 col-xl-2">
                                  <img
                                    src={game.game_Img}
                                    className="img-fluid rounded-3"
                                    alt={game.name}
                                  />
                                </div>
                                <div className="col-md-4 col-lg-4 col-xl-4">
                                  <h5 className="">
                                    {game.gameName} {game.type}
                                  </h5>
                                  <h6 className="">{game.quest_name}</h6>
                                </div>
                                <div className="col-md-2 col-lg-2 col-xl-2 offset-lg-1">
                                  <h6 className="mb-0">
                                    $ {Number(game.totalPrice).toFixed(3)}
                                  </h6>
                                </div>
                                <div className="col-md-1 col-lg-1 col-xl-1 text-end">
                                  <button
                                    className="btn btn-outline-danger"
                                    onClick={() => removeFromCart(game.id)} // Pass the id of the item to remove
                                  >
                                    <i className="fas fa-times" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          )
                      )}
                      {/* //% Service */}
                      {cartData.map((game) => (
                        <div key={game.id}>
                          {game.type === "service" && (
                            <div>
                              <hr className="my-4" />
                              <div className="row mb-4 d-flex justify-content-between align-items-center">
                                <div className="col-md-2 col-lg-2 col-xl-2">
                                  <img
                                    src={game.game_Img}
                                    className="img-fluid rounded-3"
                                    alt={game.name}
                                  />
                                </div>
                                <div className="col-md-4 col-lg-4 col-xl-4">
                                  <h5 className="">
                                    {game.gameName} {game.type}
                                  </h5>
                                  <h6 className="">Name: {game.serviceName}</h6>
                                  <h6 className="">Type: {game.serviceType}</h6>

                                  {/* Display selected radio option */}
                                  {game.selectedItem &&
                                    game.selectedItem.selectedRadio && (
                                      <h6>
                                        {game.selectedItem.selectedRadio.name} -
                                        $
                                        {Number(
                                          game.selectedItem.selectedRadio.price
                                        ).toFixed(3)}
                                      </h6>
                                    )}

                                  {/* Display selected checkboxes */}
                                  {game.selectedItem &&
                                    game.selectedItem.selectedCheckboxes &&
                                    game.selectedItem.selectedCheckboxes
                                      .length > 0 && (
                                      <div>
                                        {game.selectedItem.selectedCheckboxes.map(
                                          (checkboxId) => {
                                            // Ensure selectedRadio and its children exist before accessing them
                                            const selectedRadioChildren =
                                              game.selectedItem.selectedRadio
                                                ?.children || [];
                                            const checkbox =
                                              selectedRadioChildren.find(
                                                (child) =>
                                                  child.id === checkboxId
                                              );

                                            // Check if checkbox exists before attempting to render it
                                            if (checkbox) {
                                              return (
                                                <h6 key={checkboxId}>
                                                  {checkbox.name} - $
                                                  {Number(
                                                    checkbox.price
                                                  ).toFixed(3)}
                                                </h6>
                                              );
                                            }
                                            return null; // Return null if checkbox is not found
                                          }
                                        )}
                                      </div>
                                    )}

                                  <h6 className="">
                                    Quantity: {game.quantity}
                                  </h6>
                                </div>
                                <div className="col-md-2 col-lg-2 col-xl-2 offset-lg-1">
                                  <h6 className="mb-0">
                                    ${" "}
                                    {Number(game.serviceTotalPrice).toFixed(3)}
                                  </h6>
                                </div>
                                <div className="col-md-1 col-lg-1 col-xl-1 text-end">
                                  <button
                                    className="btn btn-outline-danger"
                                    onClick={() => removeFromCart(game.id)}
                                  >
                                    <i className="fas fa-times" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}

                      <hr className="my-4" />
                      <div className="pt-5">
                        <h6 className="mb-0 d-flex justify-content-between align-items-center">
                          {cartData.length !== 0 ? (
                            <>
                              <button
                                className="btn btn-danger"
                                onClick={() => clearCart()}
                              >
                                Clear Cart
                              </button>
                            </>
                          ) : (
                            <Link to={"/"} className="btn btn-success">
                              Pick A game!
                            </Link>
                          )}
                        </h6>
                      </div>
                    </div>
                    <div className="col-lg-4 blue-color3 text-white ">
                      <div className="p-5">
                        <h5 className="text-uppercase mb-3">Voucher code</h5>
                        <div className="mb-5">
                          <div className="voucher-form d-flex my-2">
                            <input
                              type="text"
                              id="form3Examplea2"
                              className="form-control form-control-lg"
                              placeholder="Enter voucher code"
                              value={couponCode}
                              onChange={(e) => setCouponCode(e.target.value)}
                            />
                            <button
                              className="btn btn-success ms-1"
                              onClick={validateCoupon}
                            >
                              Submit
                            </button>
                          </div>

                          {validationResult && (
                            <div
                              className={`alert p-2 ${
                                validationResult.status === "valid"
                                  ? "alert-success"
                                  : "alert-danger"
                              }`}
                              role="alert"
                            >
                              {validationResult.message}
                              {validationResult.status === "valid" && (
                                <span>
                                  Discount: {validationResult.discount}
                                </span>
                              )}
                            </div>
                          )}
                        </div>
                        <hr className="my-4" />
                        <div className="d-flex justify-content-between mb-5">
                          <h5 className="text-uppercase">Total price</h5>
                          {validationResult &&
                          validationResult.status === "valid" ? (
                            <>
                              <span className="text-success">
                                {validationResult.discount * 100}% off
                              </span>
                              <h5>$ {calculateDiscountedPrice()}</h5>
                            </>
                          ) : (
                            <h5>$ {calculateCartTotalPrice()}</h5>
                          )}
                        </div>

                        {cartData.length !== 0 && token && (
                          <button
                            className="btn btn-success"
                            onClick={() => handlePurchase()}
                            disabled={loading}
                          >
                            {loading ? "Purchasing..." : "Purchase Now"}
                          </button>
                        )}

                        {token ? null : (
                          <Link
                            to={"/login"}
                            className="btn btn-primary blue-color btn-lg"
                            data-mdb-ripple-color="dark"
                          >
                            Login to Buy
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
