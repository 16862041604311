import { createContext, useEffect, useState, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { UserContext } from "./UserContext"; // Import UserContext

export const CartContext = createContext(0);

export default function CartContextProvider({ children }) {
  const { token } = useContext(UserContext); // Get token from UserContext
  const [cartData, setCartData] = useState([]);
  const [apiType, setApiType] = useState();
  const [game, setGame] = useState();

  //@ Coupon
  const [couponCode, setCouponCode] = useState("");
  const [validationResult, setValidationResult] = useState(null);

  useEffect(() => {
    if (token) {
      const storedCartData = localStorage.getItem(`cartData_${token}`);
      if (storedCartData) {
        setCartData(JSON.parse(storedCartData));
      } else {
        setCartData([]);
      }
    } else {
      setCartData([]);
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      localStorage.setItem(`cartData_${token}`, JSON.stringify(cartData));
    }
  }, [cartData, token]);

  const addToCart = (item) => {
    setCartData((prevCartData) => [...prevCartData, { ...item, id: uuidv4() }]);
    toast.success("Item added to cart!");
  };

  const removeFromCart = (itemId) => {
    setCartData((prevCartData) => {
      const updatedCart = prevCartData.filter((item) => item.id !== itemId);
      toast.success("Item removed from cart!");
      return updatedCart;
    });
  };

  const clearCart = () => {
    setCartData([]);
  };

  const calculateCartTotalPrice = () => {
    return cartData
      .reduce((total, item) => {
        // Check if the item is a service with a serviceTotalPrice
        if (item.serviceTotalPrice) {
          return total + parseFloat(item.serviceTotalPrice);
        } else {
          return total + parseFloat(item.totalPrice || 0);
        }
      }, 0)
      .toFixed(3);
  };

  const calculateDiscountedPrice = () => {
    const maxDiscountAmount = 60; // Maximum discount amount in dollars
    const discount = validationResult?.discount || 0;
    const cartTotalPrice = calculateCartTotalPrice();

    const discountedPrice = Math.max(0, discount * cartTotalPrice);
    const cappedDiscountedPrice = Math.min(discountedPrice, maxDiscountAmount);
    const totalAfterDiscount = cartTotalPrice - cappedDiscountedPrice;

    return totalAfterDiscount.toFixed(3);
  };

  return (
    <CartContext.Provider
      value={{
        cartData,
        setCartData,
        addToCart,
        removeFromCart,
        clearCart,
        calculateCartTotalPrice,
        apiType,
        setApiType,
        couponCode,
        setCouponCode,
        validationResult,
        setValidationResult,
        calculateDiscountedPrice,
        game,
        setGame,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}
